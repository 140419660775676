$('.fewo-nav-menu').on('click', function () {
    $('.fewo-sidenav').addClass('open');
});
$('#fewo-nav-close').on('click', function () {
    $('.fewo-sidenav').removeClass('open');
});

$('.fewo-main .menu-item-has-children > a').on('click', function () {
    $('.fewo-main').addClass('open');
    $(this).parent('.menu-item-has-children').children('.sub-menu').addClass('open');
});
$('.fewo-main .menu-back').on('click', function () {
    $('.fewo-main').removeClass('open');
    $(this).parent('.sub-menu').removeClass('open');
});